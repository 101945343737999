import Label from "components/Label/Label";
import React, { FC, useState, useEffect } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { UserGlobalContext } from "user";
import bitcoins from "../../images/cryptoicons/icons8-bitcoin.svg";
import ethereums from "../../images/cryptoicons/icons8-ethereum.svg";
import litecoins from "../../images/cryptoicons/icons8-litecoin.svg";
import ripples from "../../images/cryptoicons/icons8-ripple.svg";
import zilliqas from "../../images/cryptoicons/zilliqa.svg";
import polygons from "../../images/cryptoicons/polygon-matic-icon.svg";
import {
  useNavigate,
  useLocation,
  useActionData,
  Link,
} from "react-router-dom";
import Axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import fs from "fs";
import FormData from "form-data";
// import recursive from "recursive-fs";
import Modal from "./Modal";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { gql, useMutation, useQuery } from "@apollo/client";

export interface AccountPageProps {
  className?: string;
}

const GET_PROFILE = gql`
  query GetProfile($username: String, $domainName: String!) {
    getProfile(username: $username, domainName: $domainName) {
      id
      name
      email
      domains {
        name
        walletAddress
        cost
        txhash
        website
        bitcoin
        ethereum
        litecoin
        ripple
        ziliqa
        polygon_matic
        polygon_bep20
        polygon_erc20
      }
    }
  }
`;


const UPDATE_DOMAIN_DATA = gql`
  mutation UpdateDomainData($input: DomainUpdateInput!, $username: String) {
    updateDomainData(input: $input, username: $username) {
        name
        website
        nft_id
        bitcoin
        ethereum
        litecoin
        ripple
        ziliqa
        polygon_matic
        polygon_bep20
        polygon_erc20
    }
  }
`;

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (ref.current !== null) {
      ref.current.setAttribute("directory", "");
      ref.current.setAttribute("webkitdirectory", "");
    }
  }, [ref]);
  const { LoggedIn } = UserGlobalContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (LoggedIn === false) {
      navigate("/login");
    }
  }, [LoggedIn]);
  const { state } = useLocation();
  let exdomain = "your United Protocol website";
  if (state != null) {
    exdomain = state.domain;
  }
  console.log("dmmmm", state.domain);
  const [domain, changeDomain] = useState(exdomain);
  const [nftid, changeNftid] = useState("");
  const [ipfs, changeIpfs] = useState("");
  const [walletAddress, changeWalletAddress] = useState("");
  const [bitcoin, changeBitcoin] = useState("");
  const [website, changeWebsite] = useState("");
  const [ethereum, changeEtherem] = useState("");
  const [litecoin, changeLitecoin] = useState("");
  const [ripple, changeRipple] = useState("");
  const [ziliqa, changeZiliqa] = useState("");
  const [polygonMatic, changePolygonMatic] = useState("");
  const [polygonBep20, changePolygonBep20] = useState("");
  const [polygonErc20, changePolygonErc20] = useState("");
  useEffect(() => {
    Axios.get("http://localhost:5000/metadata", { withCredentials: true })
      .then((response) => {
        console.log("respons",response.data);
        if (response.data != null) {
          const data = response.data.domain.find((element: any) => {
            return element.name === domain;
          });
          changeNftid(data.nft_id);
          changeWalletAddress(response.data.wallet_address);
          changeBitcoin(data.bitcoin);
          changeWebsite(data.website);
          changeEtherem(data.ethereum);
          changeLitecoin(data.litecoin);
          changeRipple(data.ripple);
          changeZiliqa(data.ziliqa);
          changePolygonMatic(data.polygon_matic);
          changePolygonBep20(data.polygon_bep20);
          changePolygonErc20(data.polygon_erc20);
          changeIpfs(data.ipfs_link);
        }
      })
      .catch((err) => console.log(err));
  }, [domain]);

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [isSwitchedOn, setIsSwitchedOn] = useState(true);
  const [text, setText] = useState("");
  const [states, setStates] = useState(true);
  // const [disable, setDisable] = useState(false);
  const [visible, setVisible] = useState("none");
  const [visible1, setVisible1] = useState("block");
  const [visible2, setVisible2] = useState("none");
  const [isOpen, setIsOpen] = useState(false);
  const [visible3, setVisible3] = useState("none");

  const { userProfile } = UserGlobalContext();

  const { loading, error, data } = useQuery(GET_PROFILE, {
    variables: { username: userProfile, domainName: domain },
    fetchPolicy: 'network-only', // Ensure the latest data is fetched from the server
  });
  

  const [updateDomainData] = useMutation(UPDATE_DOMAIN_DATA, {
    refetchQueries: [{ query: GET_PROFILE, variables: { username: userProfile, domainName: domain } }],
  });
  
  useEffect(() => {
    if (data && data.getProfile) {
      const targetDomain = data.getProfile.domains.find(
        (d: any) => d.name === domain
      );
      console.log("dataaaaa",targetDomain);
      if (targetDomain) {
        changeNftid(targetDomain.nft_id || "");
        changeWalletAddress(targetDomain.walletAddress || "");
        changeBitcoin(targetDomain.bitcoin || "");
        changeWebsite(targetDomain.website || "");
        changeEtherem(targetDomain.ethereum || "");
        changeLitecoin(targetDomain.litecoin || "");
        changeRipple(targetDomain.ripple || "");
        changeZiliqa(targetDomain.ziliqa || "");
        changePolygonMatic(targetDomain.polygon_matic || "");
        changePolygonBep20(targetDomain.polygon_bep20 || "");
        changePolygonErc20(targetDomain.polygon_erc20 || "");
        changeIpfs("");
      }
    }
  }, [data]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      // await updateDomainData({
      //   variables: {
      //     input: {
      //       name: domain,
      //       website,
      //       // nftid,
      //       bitcoin,
      //       ethereum,
      //       litecoin,
      //       ripple,
      //       ziliqa,
      //       polygon_matic: polygonMatic,
      //       polygon_bep20: polygonBep20,
      //       polygon_erc20: polygonErc20
      //     },
      //     username: userProfile
      //   }
      // });
      alert("Domain data updated successfully");
    } catch (err) {
      console.error("Error updating domain data:", err);
    }
  };


  const saveFile = (e: any) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
 
  const note =
    "Please open " +
    JSON.stringify(domain) +
    " in United Protocol Browser for better experience";

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  function handleWebsite() {
    setVisible2("block");
    setVisible1("none");
    setVisible3("none");
  }
  function handleCrypto() {
    setVisible1("block");
    setVisible2("none");
    setVisible3("none");
  }
  function handleTransferWebsite() {
    setVisible3("block");
    setVisible1("none");
    setVisible2("none");
  }
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Wallet Setting || House of Web3</title>
      </Helmet>
      <form
        className=""
        onSubmit={handleSubmit}
      >
        <div className="container">
          <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
            <div className="max-w-2xl">
              <h2 className="text-3xl sm:text-4xl font-semibold">
                Wallet Settings
              </h2>
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                You can set preferred addresses, website and manage other wallet
                settings.
              </span>
            </div>
            <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
            <div className="flex flex-row">
              <div className="flex flex-col md:flex-row w-2/3">
                <div className="flex-shrink-0 flex items-start"></div>
                <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-5 sm:space-y-6 md:sm:space-y-7">
                  {/* ---- */}
                  <div className="" style={{ display: visible1 }}>
                    <div className="">
                      <Label className="my-2">Website</Label>
                      <div className="mb-5 mt-1.5 flex">
                        <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          https://
                        </span>
                        <input type="hidden" value={domain} name="domain" />
                        <Input
                          name="website"
                          value={website}
                          onChange={(event: any) => {
                            changeWebsite(event.target.value);
                          }}
                          className="!rounded-l-none"
                          placeholder="yourwebsite.com"
                        />
                      </div>
                    </div>

                    {/* ---- */}
                    <div>
                      <Label className="my-2">NFT ID</Label>
                      <div className="mb-5 mt-1.5 relative text-neutral-700 dark:text-neutral-300">
                        <Input
                          name="nftid"
                          value={nftid}
                          className="!pr-10"
                          disabled
                          defaultValue="0x1bde388826caab77bfe80148abdce6830606e2c6"
                        />

                        <span
                          onClick={() => navigator.clipboard.writeText(nftid)}
                          className="absolute right-2.5 cursor-pointer top-1/2 -translate-y-1/2 "
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M21.6602 10.44L20.6802 14.62C19.8402 18.23 18.1802 19.69 15.0602 19.39C14.5602 19.35 14.0202 19.26 13.4402 19.12L11.7602 18.72C7.59018 17.73 6.30018 15.67 7.28018 11.49L8.26018 7.30001C8.46018 6.45001 8.70018 5.71001 9.00018 5.10001C10.1702 2.68001 12.1602 2.03001 15.5002 2.82001L17.1702 3.21001C21.3602 4.19001 22.6402 6.26001 21.6602 10.44Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.0603 19.3901C14.4403 19.8101 13.6603 20.1601 12.7103 20.4701L11.1303 20.9901C7.16034 22.2701 5.07034 21.2001 3.78034 17.2301L2.50034 13.2801C1.22034 9.3101 2.28034 7.2101 6.25034 5.9301L7.83034 5.4101C8.24034 5.2801 8.63034 5.1701 9.00034 5.1001C8.70034 5.7101 8.46034 6.4501 8.26034 7.3001L7.28034 11.4901C6.30034 15.6701 7.59034 17.7301 11.7603 18.7201L13.4403 19.1201C14.0203 19.2601 14.5603 19.3501 15.0603 19.3901Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div>
                      <Label className="my-2">Wallet Address</Label>
                      <div className="mb-5 mt-1.5 relative text-neutral-700 dark:text-neutral-300">
                        <Input
                          disabled
                          name="walletAddress"
                          value={walletAddress}
                          className="!pr-10"
                          defaultValue="0x1bde388826caab77bfe80148abdce6830606e2c6"
                        />

                        <span
                          onClick={() =>
                            navigator.clipboard.writeText(walletAddress)
                          }
                          className="absolute right-2.5 cursor-pointer top-1/2 -translate-y-1/2 "
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M21.6602 10.44L20.6802 14.62C19.8402 18.23 18.1802 19.69 15.0602 19.39C14.5602 19.35 14.0202 19.26 13.4402 19.12L11.7602 18.72C7.59018 17.73 6.30018 15.67 7.28018 11.49L8.26018 7.30001C8.46018 6.45001 8.70018 5.71001 9.00018 5.10001C10.1702 2.68001 12.1602 2.03001 15.5002 2.82001L17.1702 3.21001C21.3602 4.19001 22.6402 6.26001 21.6602 10.44Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.0603 19.3901C14.4403 19.8101 13.6603 20.1601 12.7103 20.4701L11.1303 20.9901C7.16034 22.2701 5.07034 21.2001 3.78034 17.2301L2.50034 13.2801C1.22034 9.3101 2.28034 7.2101 6.25034 5.9301L7.83034 5.4101C8.24034 5.2801 8.63034 5.1701 9.00034 5.1001C8.70034 5.7101 8.46034 6.4501 8.26034 7.3001L7.28034 11.4901C6.30034 15.6701 7.59034 17.7301 11.7603 18.7201L13.4403 19.1201C14.0203 19.2601 14.5603 19.3501 15.0603 19.3901Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div className="">
                      <Label className="my-2">
                        Add Cryptocurrency Addresses
                      </Label>
                      <div className="mt-1.5 flex">
                        <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          <img className="w-6 h-6" src={bitcoins}></img>
                        </span>
                        <Input
                          name="bitcoin"
                          value={bitcoin}
                          onChange={(event: any) => {
                            changeBitcoin(event.target.value);
                          }}
                          className="!rounded-l-none"
                          placeholder="Enter your Bitcoin address"
                        />
                      </div>

                      <div className="mt-1.5 flex">
                        <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          <img className="w-6 h-6" src={ethereums}></img>
                        </span>
                        <Input
                          name="ethereum"
                          value={ethereum}
                          onChange={(event: any) => {
                            changeEtherem(event.target.value);
                          }}
                          className="!rounded-l-none"
                          placeholder="Enter your Ethereum address"
                        />
                      </div>
                      <div className="mt-1.5 flex">
                        <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          <img className="w-6 h-6" src={litecoins}></img>
                        </span>
                        <Input
                          value={litecoin}
                          onChange={(event: any) => {
                            changeLitecoin(event.target.value);
                          }}
                          name="litecoin"
                          className="!rounded-l-none"
                          placeholder="Enter your Litecoin address"
                        />
                      </div>
                      <div className="mt-1.5 flex">
                        <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          <img className="w-6 h-6" src={ripples}></img>
                        </span>
                        <Input
                          value={ripple}
                          onChange={(event: any) => {
                            changeRipple(event.target.value);
                          }}
                          name="ripple"
                          className="!rounded-l-none"
                          placeholder="Enter your Ripple address"
                        />
                      </div>
                      <div className="mb-5 mt-1.5 flex">
                        <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          <img className="w-6 h-6" src={zilliqas}></img>
                        </span>
                        <Input
                          value={ziliqa}
                          onChange={(event: any) => {
                            changeZiliqa(event.target.value);
                          }}
                          name="ziliqa"
                          className="!rounded-l-none "
                          placeholder="Enter your Zillica address"
                        />
                      </div>
                    </div>

                    <div className="">
                      <Label className="my-2">Polygon Addresses</Label>
                      <div className="mt-1.5 flex">
                        <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          <img className="w-6 h-6" src={polygons}></img>
                        </span>
                        <Input
                          value={polygonMatic}
                          onChange={(event: any) => {
                            changePolygonMatic(event.target.value);
                          }}
                          name="polygonMatic"
                          className="!rounded-l-none"
                          placeholder="Enter your polygon Matic address"
                        />
                      </div>
                      <div className="mt-1.5 flex">
                        <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          <img className="w-6 h-6" src={polygons}></img>
                        </span>
                        <Input
                          value={polygonBep20}
                          onChange={(event: any) => {
                            changePolygonBep20(event.target.value);
                          }}
                          name="polygonBep20"
                          className="!rounded-l-none"
                          placeholder="Enter your polygon BEP20 address"
                        />
                      </div>
                      <div className="mb-5 mt-1.5 flex">
                        <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          <img className="w-6 h-6" src={polygons}></img>
                        </span>
                        <Input
                          value={polygonErc20}
                          onChange={(event: any) => {
                            changePolygonErc20(event.target.value);
                          }}
                          name="polygonErc20"
                          className="!rounded-l-none"
                          placeholder="Enter your polygon ERC20 address"
                        />
                      </div>
                    </div>
                    <div className="">
                      <button
                        className=" w-full bg-[#0ac74c] hover:opacity-[0.9] text-black font-bold py-2 px-4 rounded-full"
                        type="submit"
                      >
                        Update Wallet
                      </button>
                    </div>
                  </div>
                  <div className="" style={{ display: visible2 }}>
                    <Label>IPFS Hash (Your Web3 Profile)</Label>
                    <div className="mt-1.5 flex">
                      <Input
                        value={ipfs}
                        name="ipfs"
                        disabled
                        className=""
                        placeholder="IPFS Hash"
                      />
                    </div>

                    <div
                      style={{ display: visible }}
                      className="flex-grow my-10 md:mt-0  max-w-4xl space-y-5 sm:space-y-6 md:sm:space-y-7"
                    >
                      <div>
                        <div>
                          <div className="flex">
                            <h1 className="mt-5 text-center text-xl ">
                              Instructions to create custom website{" "}
                              <span
                                onClick={handleOpenModal}
                                className="text-blue-800 underline cursor-pointer"
                              >
                                click here
                              </span>
                            </h1>
                            <Modal isOpen={isOpen} onClose={handleCloseModal}>
                              <h2 className="text-xl font-bold">For React</h2>
                              <ul>
                                <li className="text-sm p-1 list-disc">
                                  to the package.json file, add the following
                                  entry to the top of the file : "homepage":
                                  "./",
                                </li>
                                <li className="text-sm p-1 list-disc">
                                  build your app by running the following in the
                                  root of your project directory : npm run build
                                </li>
                                <li className="text-sm p-1 list-disc">
                                  you should now have an updated IPFS compatible
                                  build in the “build” folder of your React app.
                                </li>
                              </ul>
                              <h2 className="text-xl  mt-5 font-bold">
                                For Angular
                              </h2>
                              <ul>
                                <li className="text-sm p-1 list-disc">
                                  <p>
                                    In the index.html, change the &lt;base
                                    href&gt; tag to:
                                    <br />
                                    &lt;script&gt; <br />
                                    document.write('&lt;base
                                    href="'+window.location.pathname+'"/&gt;');
                                    <br />
                                    &lt;/script&gt;
                                  </p>
                                </li>
                                <li className="text-sm p-1 list-disc">
                                  build your app by running the following in the
                                  root of your project directory : ng build
                                  --prod --aot
                                </li>
                                <li className="text-sm p-1 list-disc">
                                  you should now have an updated IPFS compatible
                                  build in the “dist” folder of your Angular
                                  app.
                                </li>
                              </ul>
                              <h2 className="text-xl mt-5 font-bold">
                                For Vue
                              </h2>
                              <ul>
                                <li className="text-sm p-1 list-disc">
                                  in the root directory of your Vue application,
                                  create a file called “vue.config.js”. In this
                                  file put the following : module.exports =
                                  publicPath : './'
                                </li>
                                <li className="text-sm p-1 list-disc">
                                  build your app by running the following in the
                                  root of your project directory : npm run build
                                </li>
                                <li className="text-sm p-1 list-disc">
                                  you should now have an updated IPFS compatible
                                  build in the “dist” folder of your Vue app.
                                </li>
                              </ul>
                            </Modal>
                          </div>
                        </div>
                      </div>

                      <div className="App p-2 md:sm:space-y-2 text-center border-2 rounded-xl py-5">
                        <p className=" text-gray-400">
                          Upload / Drag and Drop files here
                        </p>
                        <p className=" text-gray-400">
                          Maximum file size limit : 50MB
                        </p>
                        <p className=" text-gray-400">{text}</p>
                        <div className="text-center">
                          <input
                            style={{ color: "gray" }}
                            type="file"
                            className="w-full h-48 border-dashed border-2 rounded-xl py-16 m-auto px-24"
                            onChange={saveFile}
                          />
                          <input type="hidden" name="domain" value={domain} />
                        </div>
                        <button
                          className="bg-[#0ac74c] hover:opacity-[0.9] text-dark  py-2 px-4 rounded-full"
                          // onClick={uploadFile}
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                    <div className="flex-grow mb-10 md:mt-0 p-1 max-w-4xl space-y-5 sm:space-y-6 md:sm:space-y-7 text-center">
                      <h1 className="font-bold border-2 rounded-xl py-2">
                        {note}{" "}
                      </h1>
                    </div>
                  </div>

                  {/* <div style={{ display: visible3 }} className="dark:bg-neutral-800 flex flex-col justify-start items-start bg-gray-50 py-4 md:py-6 px-5   w-full">
                    <h1 className="text-2xl font-bold">Transfer domain </h1>


                    <p className="dark:text-neutral-50 xl:text-lg mt-3   text-gray-800">
                      Email
                    </p>
                    <div className="w-full flex border-b mt-1 border-teal-500 ">
                      <input
                        className="dark:text-neutral-50 bg-transparent border-none  w-full text-gray-700 "
                        type="text"
                        placeholder="Add recipient email"
                        aria-label=""
                      />
                    </div>
                    <p className="dark:text-neutral-50 xl:text-lg mt-3   text-gray-800">
                      Personalised Message
                    </p>
                    <div className="w-full flex border-b mt-1 border-teal-500 ">
                      <input
                        className="dark:text-neutral-50 bg-transparent border-none  w-full text-gray-700 "
                        type="text"
                        placeholder="Add a personalised message"
                        aria-label=""
                      />
                    </div>
                    <div className="flex items-center mt-8">
                      <input id="link-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-black-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                      <label htmlFor="link-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">I understand that I am transfering ownership of this domain and this action is irreversible</label>
                    </div>

                    <div className="flex justify-center">
                      <ButtonSecondary className="ml-1 mt-5" >
                        Send
                      </ButtonSecondary>
                    </div>
                  </div> */}
                </div>
              </div>
              <div>
                <div className="flex min-h-screen ml-12">
                  <div className="w-64 bg-gray-200 rounded">
                    <nav className="mt-6">
                      <ul className="space-y-2">
                        <li>
                          <a
                            onClick={handleCrypto}
                            className="flex items-center cursor-pointer px-6 py-3 text-gray-500 hover:bg-gray-700 hover:text-white"
                          >
                            <svg
                              className="w-5 h-5 mr-2"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M3 5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5zm2-2V2h4v1H5zm9.293 1.293l.707.707a1 1 0 0 1-1.414 0l-.707-.707A.996.996 0 0 1 12 1a1 1 0 0 1 .707.293zm4 2l.707-.707a1 1 0 0 0-1.414 0l-.707.707a1 1 0 0 0 0 1.414l.707.707a1 1 0 0 0 1.414 0l.707-.707a1 1 0 0 0 0-1.414zM5 11h1v2H5v-2zm13-1h-1v4h1v-4zM8 11H5v4h3v-4zm9-1h-3v6h3v-6zm-9 7H5v1h3v-1zm5 0h-3v1h3v-1zm4 0h-3v1h3v-1zm-1-2h-3v6h3v-6zm-1-2h-3v1h3v-1z"
                              />
                            </svg>
                            Crypto
                          </a>
                        </li>
                        <li>
                          <a
                            aria-disabled
                            // onClick={handleWebsite}
                            className="flex  items-center px-6 py-3 text-gray-500 "
                          >
                            <svg
                              className="w-5 h-5 mr-2"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M11 2a9 9 0 1 0 0 18 9 9 0 0 0 0-18zm1 13h5a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2h7V9.414l-3.293 3.293a1 1 0 0 1-1.414-1.414l5-5a1 1 0 0 1 1.414 0l5 5a1 1 0 1 1-1.414 1.414L12 9.414V15z"
                              />
                            </svg>
                            Website(Coming Soon)
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="flex-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AccountPage;

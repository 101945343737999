import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/unitedXtld.png";
import logoLightImg from "images/unitedXtld-white.png"

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "xl:w-[100%]  md:w-[30%] sm:w-[35%] w-[60%]",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo mt-4 inline-block text-primary-6000 ${className}`}
    >
      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {img ? (
        <img
          className={`block  ${imgLight ? "dark:hidden" : ""}`}
          src={img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden dark:block"
          src={imgLight}
          // width={250}
          alt="Logo-Light"
        />
      )}
      {/* <div style={{clear:"both"}}></div>
      <h5>House of Web3</h5> */}
    </Link>
  );
};

export default Logo;

import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { UserGlobalContext } from "user";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import Img1 from "../../images/avatar/avatar6.png";
import Img2 from "../../images/avatar/avatar7.png";
import Img3 from "../../images/avatar/avatar8.png";
import Img4 from "../../images/avatar/avatar9.png";
import Img5 from "../../images/avatar/avatar10.png";
import Img6 from "../../images/avatar/avatar11.png";
import Img7 from "../../images/avatar/avatar12.png";
import Img8 from "../../images/avatar/avatar13.png";
import Img9 from "../../images/avatar/avatar14.png";  
import { gql, useMutation, useQuery } from "@apollo/client";
import Logo from "shared/Logo/Logo";

export interface AccountPageProps {
  className?: string;
}

const GET_PROFILE = gql`
  query GetProfile {
    getProfile {
      id
      name
      email
      bio
      website
      occupation
      facebook
      twitter
      telegram
      selectedAvatar
    }
  }
`;


const UPDATE_PROFILE = gql`
  mutation UpdateProfile($input: ProfileUpdateInput!) {
    updateProfile(input: $input) {
      id
      name
      email
      bio
      website
      occupation
      facebook
      twitter
      telegram
      selectedAvatar
    }
  }
`;

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { LoggedIn, userProfile } = UserGlobalContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (LoggedIn === false) {
      console.log("check logged in account page: ", LoggedIn);
      navigate("/login");
    }
  });
  const [name, changeName] = useState("");
  const [email, changeEmail] = useState("");
  const [bio, changeBio] = useState("");
  const [website, changeWebsite] = useState("");
  const [occupation, changeOccupation] = useState("ST");
  const [twitter, changeTwitter] = useState("");
  const [facebook, changeFacebook] = useState("");
  const [telegram, changeTelegram] = useState("");

  const { data, loading, error } = useQuery(GET_PROFILE);

  const [updateProfile] = useMutation(UPDATE_PROFILE);

  useEffect(() => {
    if (data) {
      const profile = data.getProfile;
      changeName(profile.name);
      changeEmail(profile.email);
      changeBio(profile.bio);
      changeWebsite(profile.website);
      changeOccupation(profile.occupation);
      changeTwitter(profile.twitter);
      changeFacebook(profile.facebook);
      changeTelegram(profile.telegram);
      setSelectedClient(profile.selectedAvatar);
    }
  }, [data]);


  const items = [
    {
      label: "Avatar-01",
      value: "1",
    },
    {
      label: "Avatar-02",
      value: "2",
    },
    {
      label: "Avatar-03",
      value: "3",
    },
    {
      label: "Avatar-04",
      value: "4",
    },
    {
      label: "Avatar-05",
      value: "5",
    },
    {
      label: "Avatar-06",
      value: "6",
    },
    {
      label: "Avatar-07",
      value: "7",
    },
    {
      label: "Avatar-08",
      value: "8",
    },
    {
      label: "Avatar-09",
      value: "9",
    },
  ];
  const [selectedClient, setSelectedClient] = useState("0");
  function handleSelectChange(event: any) {
    setSelectedClient(event.target.value);
    console.log(event.target.value);
  }
  console.log("emails: " + typeof userProfile + userProfile);
  const [url, setUrl] = useState(Img1);
  // console.log(selectedClient);
  useEffect(() => {
    if (selectedClient === "1") {
      setUrl(Img1);
    }
    if (selectedClient === "2") {
      setUrl(Img2);
    }
    if (selectedClient === "3") {
      setUrl(Img3);
    }
    if (selectedClient === "4") {
      setUrl(Img4);
    }
    if (selectedClient === "5") {
      setUrl(Img5);
    }
    if (selectedClient === "6") {
      setUrl(Img6);
    }
    if (selectedClient === "7") {
      setUrl(Img7);
    }
    if (selectedClient === "8") {
      setUrl(Img8);
    }
    if (selectedClient === "9") {
      setUrl(Img9);
    }
  }, [selectedClient]);
  const handleUpdateProfile = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Create the input object according to ProfileUpdateInput type
    const profileInput = {
      name,
      bio,
      website,
      occupation,
      facebook,
      twitter,
      telegram,
      selectedAvatar: selectedClient
    };

    updateProfile({
      variables: {
        input: profileInput,  // Pass the input object
      }
    })
      .then((response) => {
        alert("Profile updated successfully");
        console.log('Update response:', response);
      })
      .catch((err) => {
        console.error('Update error:', err);
        alert(`Error updating profile: ${err.message}`);
      });
  };

  // if (loading) return <p className="flex h-screen w-screen items-center justify-center">
  //   <div className="w-[30%] flex justify-center pointer-events-none">
      
  //   <Logo />
  //   </div>
  // </p>;
  // if (error) return <p className="flex h-screen w-screen items-center justify-center">Error loading profile data</p>;

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Profile || House of Web3</title>
      </Helmet>
      <form
        className="container"
        // encType="multipart/form-data"
        onSubmit={handleUpdateProfile}
      >
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              Profile settings
            </h2>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              You can set preferred display name, details and manage other
              personal settings.
            </span>
          </div>
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col flex-shrink-0  items-start items-center">
              <div className="relative rounded-full overflow-hidden flex">
                {/* <Avatar sizeclassName="w-32 h-32" /> */}

                <img className="w-24 h-24" src={url}></img>
              </div>
              <div className="flex justify-center">
                <div className="my-2 w-auto">
                  <input type="hidden" value={selectedClient} name="pic" />
                  <select
                    onChange={handleSelectChange}
                    value={selectedClient}
                    className="form-select appearance-none
      block
      w-auto
      pr-8
      py-1
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded-full
      transition
      ease-in-out
      dark:bg-neutral-800
      dark:text-neutral-50"
                  >
                    {items.map((option, index) => (
                      <option value={option.value} key={index}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-5 sm:space-y-6 md:sm:space-y-7">
              {/* ---- */}
              <div>
                <Label>Username</Label>
                <Input
                  onChange={(event: any) => {
                    changeName(event.target.value);
                  }}
                  name="name"
                  value={name}
                  className="mt-1.5"
                  placeholder={name}
                  // placeholder="Your Name"
                />
              </div>

              {/* ---- */}
              <div>
                <Label>Email</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-envelope"></i>
                  </span>
                  <Input
                    name="email"
                    value={email}
                    className="!rounded-l-none"
                    disabled
                    placeholder={email}
                  />
                </div>
              </div>

              {/* ---- */}
              <div>
                <Label>Bio</Label>
                <Textarea
                  onChange={(event: any) => {
                    changeBio(event.target.value);
                  }}
                  name="bio"
                  value={bio}
                  rows={5}
                  className="mt-1.5"
                  placeholder={bio}
                />
              </div>

              {/* ---- */}
              <div className="">
                <Label>Website</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    https://
                  </span>
                  <Input
                    onChange={(event: any) => {
                      changeWebsite(event.target.value);
                    }}
                    name="website"
                    value={website}
                    className="!rounded-l-none"
                    placeholder={website}
                  />
                </div>
              </div>

              <label
                htmlFor="occupation"
                className="block text-sm font-medium text-gray-900 dark:text-white"
              >
                Occupation
              </label>
              <select
                onChange={(event: any) => {
                  changeOccupation(event.target.value);
                }}
                name="occupation"
                value={occupation}
                style={{ color: "#696969" }}
                id="occupation"
                defaultValue={occupation}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option>Company</option>
                <option value="EM">Employee</option>
                <option value="ST">Student</option>
                <option value="EN">Engineer</option>
              </select>

              <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 sm:gap-2.5">
                <div>
                  <Label>Facebook</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl lab la-facebook-f"></i>
                    </span>
                    <Input
                      onChange={(event: any) => {
                        changeFacebook(event.target.value);
                      }}
                      name="facebook"
                      value={facebook}
                      className="!rounded-l-none"
                      placeholder={facebook}
                      sizeClass="h-11 px-4 pl-2 pr-3"
                    />
                  </div>
                </div>
                <div>
                  <Label>Twitter</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl lab la-twitter"></i>
                    </span>
                    <Input
                      onChange={(event: any) => {
                        changeTwitter(event.target.value);
                      }}
                      name="twitter"
                      value={twitter}
                      className="!rounded-l-none"
                      placeholder={twitter}
                      sizeClass="h-11 px-4 pl-2 pr-3"
                    />
                  </div>
                </div>
                <div>
                  <Label>Telegram</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl lab la-telegram-plane"></i>
                    </span>
                    <Input
                      onChange={(event: any) => {
                        changeTelegram(event.target.value);
                      }}
                      name="telegram"
                      value={telegram}
                      className="!rounded-l-none"
                      placeholder={telegram}
                      sizeClass="h-11 px-4 pl-2 pr-3"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-2">
                <button
                  type="submit"
                  className=" w-full bg-[#0ac74c] hover:opacity-[0.9] text-black font-bold py-2 px-4 rounded-full"
                >
                  Update profile
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AccountPage;

import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import CartItemsList from "./CartItemsList";
import emptycart2 from "../../images/cartWhite.png";
import emptycart1 from "../../images/cartBlack.png";
import { UserGlobalContext } from "user";
import { gql, useLazyQuery } from "@apollo/client";

interface DomainData {
  available: boolean;
  cost: number;
  message: string;
}
interface CouponData {
  message: string;
  coupon: {
    code: string;
    amount?: number;
    discountPercentage?: number;
  } | null;
}

const SEARCH_DOMAIN = gql`
  query SearchDomain($name: String!) {
    searchDomain(name: $name) {
      available
      cost
      message
    }
  }
`;

const VERIFY_COUPON = gql`
  query VerifyCoupon($code: String!) {
    verifyCoupon(code: $code) {
      message
      coupon {
        code
        amount
        discountPercentage
      }
    }
  }
`;

const Ordersum3 = () => {
  const { LoggedIn } = UserGlobalContext();
  const { changeCartItems, changeArray, array, darkMode } = UserGlobalContext();
  // const [domainData, changeDomainData] = useState({
  //   cost: 0,
  //   type: "Getting Data...",
  // });
  const [domainData, setDomainData] = useState<DomainData[]>([]);
  const [searchDomain] = useLazyQuery(SEARCH_DOMAIN);
  const [verifyCoupon, { data: couponData }] = useLazyQuery<{ verifyCoupon: CouponData }>(VERIFY_COUPON);


  useEffect(() => {
    changeCartItems(array.length);
    console.log("changeCartItems",array);
  }, [array, changeCartItems]);

  const [searchResult, setSearchResult] = useState({
    available: false,
    cost: 20,
    message: "",
  });

  // useLazyQuery for conditional query execution
const fetchDomainData = useCallback(async () => {
    const fetchedData: DomainData[] = [];
    let costAccumulator = 20;

    await Promise.all(array.map(async (domain) => {
      const { data } = await searchDomain({ variables: { name: domain } });
      if (data && data.searchDomain) {
        fetchedData.push(data.searchDomain);
        costAccumulator += data.searchDomain.cost || 20;
      }
    }));

    setDomainData(fetchedData);
    setTotalCost(costAccumulator);
  }, [array, searchDomain]);

  // Re-fetch data and recalculate total cost whenever `array` changes
  useEffect(() => {
    fetchDomainData();
  }, [array, fetchDomainData]);


  async function chk() {
    console.log("removing not available items from array");
    console.log("array_before:" + array);
    console.log("cookie_before:" + document.cookie);
    var newArray = array;
    var newArray2 = array;
    var checkStatus: any = [];

    let length = array.length;
    for (let i = 0; i < length; i++) {
      console.log("This is a check to count no. of times loop runs");
      // await Axios.get("http://localhost:5000/search/" + newArray2[i])
      //   .then((response) => {
      //     checkStatus.push(response.data.type);
      //   })
      //   .catch((err) => console.log(err));
      searchDomain({ variables: { name: newArray2[i] } });
      checkStatus.push(searchResult.available);
    }
    console.log(checkStatus);
    var count = 0;
    for (let j = 0; j < length; j++) {
      if (!checkStatus[j]) {
        count = count + 1;
        newArray.splice(newArray2[j], 1);
      }
    }

    console.log("newArray: " + newArray);
    console.log("count=" + count + " length=" + length);
    // if (count == length) {
    document.cookie = "cartItem = " + JSON.stringify(newArray);
    changeArray(newArray);
    changeCartItems(array.length);
    // }
    console.log("array_after:" + array);
    console.log("cookie_after=" + document.cookie);
  }
  // useEffect(() => {
  //   chk();
  // }, []);

  let navigate = useNavigate();
  const [cost, changeCost] = useState(20);
  const [totalCost, setTotalCost] = useState(20);

  // useEffect(() => {
  //   setTotalCost(0);
  //   for (var i = 0; i < array.length; i++) {
  //     // Axios.get("http://localhost:5000/search/" + array[i])
  //     //   .then((response) => {
  //     //     setTotalCost((totalCost) => {
  //     //       return totalCost + response.data.cost;
  //     //     });
  //     //   })
  //     //   .catch((err) => console.log(err));
  //     searchDomain({ variables: { name: array[i] } });
  //     setTotalCost((totalCost) => {
  //       return totalCost + searchResult.cost;
  //     });
  //   }
  // }, [array, document.cookie]);

  const [coupon, changeCoupon] = useState("");
  const [couponApplied, changeCouponApplied] = useState("No Coupon!!");

  function HandleClick() {
    if (LoggedIn === false) {
      alert("Please Log Into Your Account!!");
      navigate("/login");
    } else {
      // Create a URLSearchParams object to properly encode the parameters
      const queryParams = new URLSearchParams();
      
      // Add domains as a comma-separated string
      queryParams.append("domains", array.join(","));
      
      // Add total cost
      queryParams.append("totalCost", cost.toString());
      
      // Add coupon code if it exists
      if (couponApplied !== "No Coupon!!") {
        queryParams.append("coupon", couponApplied);
      }

      // Navigate to payment2 with query parameters
      navigate(`/payment2?${queryParams.toString()}`);
    }
  }

  function handleClickChild(event: any) {
    fetchDomainData();
    const updatedArray = [...array];
    updatedArray.splice(event.target.value, 1);
    changeArray(updatedArray);
    changeCartItems(updatedArray.length);
    document.cookie = "cartItem = " + JSON.stringify(updatedArray);
    
    // Re-run `fetchDomainData` to update total cost
  }

  function HandleChange(event: any) {
    changeCoupon(event.target.value);
  }
  // function VerifyCoupon() {
  //   changeCouponApplied(coupon);
  // }

  // function VerifyCoupon() {
  //   console.log("coupon", coupon);
  //   Axios.get(`http://localhost:5000/couponVerify/${coupon}`)
  //     .then((response) => {
  //       console.log("response", response);
  //       if (response.data.message === "Invalid Code") {
  //         changeCost(totalCost);
  //         alert("Wrong Code!!");
  //       } else if (response.data.amount) {
  //         const newCost = totalCost - response.data.amount;

  //         changeCost(newCost);
  //         alert("Code Applied!!");
  //       } else if (response.data.percentage) {
  //         const discount = (response.data.percentage * totalCost) / 100;
  //         console.log("discount", discount);
  //         const newCost = totalCost - discount;
  //         changeCost(newCost);
  //         alert("Code Applied!!");
  //       }
  //       changeCouponApplied(coupon);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // useEffect(() => {
  //   changeCost(totalCost);
  // }, [totalCost]);
  async function VerifyCoupon() {
    try {
      const { data } = await verifyCoupon({
        variables: { code: coupon }
      });

      if (!data) return;

      const { message, coupon: couponResult } = data.verifyCoupon;

      if (message === "Invalid Code" || message === "Coupon Expired") {
        changeCost(totalCost);
        alert(message);
        return;
      }

      if (couponResult) {
        if (couponResult.amount) {
          const newCost = totalCost - couponResult.amount;
          changeCost(newCost);
        } else if (couponResult.discountPercentage) {
          const discount = (couponResult.discountPercentage * totalCost) / 100;
          const newCost = totalCost - discount;
          changeCost(newCost);
        }
        alert("Coupon Applied Successfully!");
        changeCouponApplied(coupon);
      }
    } catch (error) {
      console.error("Error verifying coupon:", error);
      alert("Error verifying coupon. Please try again.");
    }
  }

  useEffect(() => {
    changeCost(totalCost);
  }, [totalCost]);

  console.log("discount", totalCost - cost);

  useEffect(() => {
    const checkbox = document.getElementById(
      "link-checkbox"
    ) as HTMLInputElement;
    const giftDiv = document.getElementById("giftDiv") as HTMLDivElement;
    if (checkbox !== null && giftDiv !== null) {
      giftDiv.style.display = "none";
      if (checkbox) {
        checkbox.addEventListener("change", function () {
          if (checkbox.checked) {
            giftDiv.style.display = "block";
          } else {
            giftDiv.style.display = "none";
          }
        });
      }
    }
  }, []);

  if (array.length === 0) {
    return (
      <div className="my-36 ">
        <div className="m-auto text-4xl flex gap-10 text-center flex-col items-center justify-center">
          {darkMode ? (
            <img className="w-72 h-72" alt="Cart Empty" src={emptycart2} />
          ) : (
            <img className="w-72 h-72" alt="Cart Empty" src={emptycart1} />
          )}
          Your Cart is Empty!!
          <div className="mt-4">
            <ButtonPrimary>
              <Link to="/">Continue Shopping</Link>
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container">
        <div className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
          <div className="flex justify-start item-start space-y-2 flex-col ">
            <h1 className="text-3xl lg:text-4xl font-semibold leading-7 lg:leading-9  dark:text-neutral-50 text-gray-800">
              Order Summary
            </h1>
          </div>
          <div className=" mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch  w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
            <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
              <div className="dark:bg-neutral-800 flex flex-col justify-start items-start bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
                <p className="dark:text-neutral-50 text-lg md:text-xl font-semibold leading-6 xl:leading-5 text-gray-800">
                  Customer’s Cart
                </p>

                {array.map((item: any, index: any) => {
                  return (
                    <CartItemsList
                      id={index}
                      key={index}
                      change={handleClickChild}
                      name={item}
                    />
                  );
                })}
              </div>

              <div className="flex justify-center md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
                <div className="dark:bg-neutral-800  flex flex-col justify-center px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6   ">
                  <h3 className="dark:text-neutral-50 text-xl font-semibold leading-5 text-gray-800">
                    Discount : ${(totalCost - cost).toFixed(2)}
                  </h3>
                  <p className="dark:text-neutral-50 font-semibold text-gray-800">
                    Enter Discount Coupon Here
                  </p>
                  <div className="flex flex-row w-full">
                    {/* <div className="flex justify-center items-center space-x-4"> */}
                    {/* <div className="flex flex-col justify-start items-center"> */}

                    {/* <span className="font-normal">Some content</span> */}
                    <div className="w-full  flex">
                      <div className="w-full flex border-b border-teal-500 pt-2">
                        <input
                          onChange={HandleChange}
                          value={coupon}
                          className="dark:text-neutral-50 bg-transparent border-none mr-1  w-full text-gray-700 "
                          type="text"
                          placeholder="Add coupon code"
                          aria-label=""
                        />
                      </div>
                      <ButtonSecondary onClick={VerifyCoupon}>
                        Verify
                      </ButtonSecondary>
                    </div>
                  </div>
                </div>
                <div className="dark:bg-neutral-800 flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6   ">
                  <h3 className="dark:text-neutral-50 text-xl font-semibold leading-5 text-gray-800">
                    Summary
                  </h3>
                  <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                    <div className="flex justify-between  w-full">
                      <p className="dark:text-neutral-50 text-base leading-4 text-gray-800">
                        Subtotal
                      </p>
                      <p className="dark:text-neutral-50 text-base leading-4 text-gray-600">
                        ${totalCost}
                      </p>
                    </div>
                    <div className="flex justify-between items-center w-full">
                      <p className="dark:text-neutral-50 text-base leading-4 text-gray-800">
                        Discount
                        {/* <span className="bg-gray-200 p-1 text-xs font-medium leading-3  text-gray-800">STUDENT</span> */}
                      </p>
                      <p className="dark:text-neutral-50 text-base leading-4 text-gray-600">
                        -{(((totalCost - cost) * 100) / totalCost).toFixed(2)} %
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="dark:text-neutral-50 text-base font-semibold leading-4 text-gray-800">
                      Total
                    </p>
                    <p className="dark:text-neutral-50 text-base font-semibold leading-4 text-gray-600">
                      ${cost}
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full flex justify-center pt-8 items-center">
                <ButtonPrimary
                  onClick={() => {
                    HandleClick();
                  }}
                >
                  Continue to payment
                </ButtonPrimary>
                {/* <button className="hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-5 w-96 md:w-full bg-gray-800 text-base font-medium leading-4 text-white">Buy now</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Ordersum3;

import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Textarea from "shared/Textarea/Textarea";
import Heading from "shared/Heading/Heading";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "shared/Input/Input";
import { UserGlobalContext } from "user";
import Modal from "./PageUpdateTokens/Modal";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import "./style.css";

export interface AuthorPageProps {
  className?: string;
}

const AuthorPage: FC<AuthorPageProps> = ({ className = "" }) => {
  const [subject, setSubject] = useState("");
  const [email1, setEmail1] = useState("");
  const [content, setContent] = useState("");
  const [alertText, setAlertText] = useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  
  const Alert = (text: any) => {
    return (
      <>
        {showAlert ? (
          <div className="text-white px-6 py-8 border-0 rounded relative mb-4 bg-emerald-500">
            <span className="text-xl inline-block mr-5 align-middle">
              <i className="fas fa-bell" />
            </span>
            <span className="inline-block align-middle mr-8">
              {alertText || text}
            </span>
            <button
              className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
              onClick={() => setShowAlert(false)}
            >
              <span>×</span>
            </button>
          </div>
        ) : null}
      </>
    );
  };

  const [email, changeEmail] = useState("Your email");
  const { cartItems, changeCartItems, array, changeArray, LoggedIn } = UserGlobalContext();
  let { state } = useLocation();
  let navigate = useNavigate();
  
  let searched = "";
  if (state != null) {
    searched = state.domain.toLowerCase();
  }

  const [input, changeInput] = useState(searched);
  const [tldData, setTldData] = useState([
    {
      domain: ".hello",
      available: true,
      cost: 12,
      message: "Premium TLD",
      owner: "",
    },
    {
      domain: ".mh",
      available: true,
      cost: 15,
      message: "Premium TLD",
      owner: "",
    },
    {
      domain: ".sent",
      available: true,
      cost: 10,
      message: "Premium TLD",
      owner: "",
    },
    {
      domain: ".george",
      available: true,
      cost: 20,
      message: "Premium TLD",
      owner: "",
    },
    {
      domain: ".trace",
      available: true,
      cost: 18,
      message: "Premium TLD",
      owner: "",
    },
  ]);

  const [domainData, setDomainData] = useState([
    {
      domain: "hello.hello",
      available: true,
      cost: 25,
      message: "Premium Domain",
      owner: "",
    },
    {
      domain: "mh.mh",
      available: true,
      cost: 30,
      message: "Premium Domain",
      owner: "",
    },
    {
      domain: "sent.sent",
      available: true,
      cost: 22,
      message: "Premium Domain",
      owner: "",
    },
    {
      domain: "george.george",
      available: true,
      cost: 28,
      message: "Premium Domain",
      owner: "",
    },
    {
      domain: "trace.trace",
      available: true,
      cost: 24,
      message: "Premium Domain",
      owner: "",
    },
  ]);

  const [stldData, setStldData] = useState([
    {
      domain: "bhavesh.metaverse",
      available: true,
      cost: 35,
      message: "Premium Domain",
      owner: "",
    },
    {
      domain: "yash.yash",
      available: true,
      cost: 40,
      message: "Premium Domain",
      owner: "",
    },
    {
      domain: "hey.web3",
      available: true,
      cost: 45,
      message: "Premium Domain",
      owner: "",
    },
    {
      domain: "hello.x",
      available: true,
      cost: 50,
      message: "Premium Domain",
      owner: "",
    },
  ]);

  const [searchResult, setSearchResult] = useState({
    available: true,
    cost: 20,
    message: "Domain available",
    owner: "",
  });

  const [text1, setText1] = useState("Add to Cart");
  const [text2, setText2] = useState("Add to Cart");
  const [style1, setStyle1] = useState(
    "mr-3 my-2 bg-[#0ac74c] hover:opacity-[0.9] text-[#000] text-white font-bold py-2 px-4 rounded"
  );
  const [isOpen, setIsOpen] = useState(false);
  const [cartFlag, setCartFlag] = useState(false);
  const [selectedButton, setSelectedButton] = useState<string | null>("tld");

  useEffect(() => {
    if (array.includes(input)) {
      setText2("Go to Cart");
    } else {
      setText2("Add to Cart");
    }
  }, [array, input]);

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  const handleClickCart = (event: any) => {
    const selectedDomain = event.target.value;

    if (!LoggedIn) {
      alert("Please Login First");
    } else {
      if (cartItems !== 0) {
        if (array.includes(selectedDomain)) {
          setCartFlag(true);
          alert("This domain is already added to cart");
        } else {
          setCartFlag(false);
          const updatedArray = [...array, selectedDomain];
          changeCartItems(updatedArray.length);
          changeArray(updatedArray);
          document.cookie = "cartItem=" + JSON.stringify(updatedArray);
          setText2("Go to Cart");
        }
      } else {
        changeCartItems(1);
        changeArray([selectedDomain]);
        document.cookie = "cartItem=" + JSON.stringify([selectedDomain]);
        setText2("Go to Cart");
      }
    }
  };

  const handleGoToCart = () => {
    navigate("/cart");
  };

  const handleRemoveFromCart = (domain: string) => {
    const updatedArray = array.filter((item) => item !== domain);
    changeCartItems(updatedArray.length);
    changeArray(updatedArray);
    document.cookie = "cartItem=" + JSON.stringify(updatedArray);
    setText2("Add to Cart");
  };

  const HandleAlert = () => {
    setAlertText("This domain is not available");
    setCartFlag(true);
    setShowAlert(true);
  };

  const HandleChange = (event: any) => {
    changeInput(event.target.value.toLowerCase());
  };

  const HandleSubmit = (e: any) => {
    e.preventDefault();
    const domainRegex = /^\.?[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)?$/;

    if (!input || !domainRegex.test(input)) {
      alert("Invalid domain or TLD format");
      return;
    }
    
    if (!input.includes(".")) {
      changeInput("." + input);
    }
    
    // Simulate domain search with static result
    setSearchResult({
      available: true,
      cost: 20,
      message: "Domain available",
      owner: "",
    });
  };

  const handleRadioClick = (buttonValue: string) => {
    if (selectedButton === buttonValue) {
      setSelectedButton(null);
    } else {
      setSelectedButton(buttonValue);
    }
  };

  return (
    <div className={`nc-AuthorPage  ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Search || House of Web3</title>
      </Helmet>

      <div className="fixed top-0 inset-x-0 z-50">
        <Alert />
      </div>

      <Heading
        className="mt-20 mb-10 text-neutral-900 dark:text-neutral-50"
        fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
        isCenter
        desc=""
      >
        Search Result
      </Heading>

      <div className="container w-full md:w-3/4">
        <div className="relative">
          <form onSubmit={HandleSubmit}>
            <Input
              value={input}
              onChange={HandleChange}
              // onKeyDown={HandleEnter}
              type="search"
              placeholder="Search your domain"
              className="pr-16"
              sizeClass="h-[42px] pl-4 py-6"
            />
            <span className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500">
              <button type="submit">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </span>
          </form>
        </div>

        {/* Toaster will be here */}
        {/* <Toaster position="top-right" /> */}

        {/* Radio buttons below the input */}
        <div className="flex justify-start space-x-4 mt-4">
          <label className="inline-flex items-center">
            <span className="mr-3 bg-transparent text-black dark:text-white">
              TLD First
            </span>
            <div
              className={`w-4 h-4 border border-gray-300 rounded-sm flex items-center justify-center cursor-pointer ${
                selectedButton === "tld" ? "bg-green-500" : "bg-transparent"
              }`}
              onClick={() => handleRadioClick("tld")}
            >
              {selectedButton === "tld" && (
                <svg
                  className="w-4 h-4 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
              )}
            </div>
          </label>

          <label className="inline-flex items-center">
            <span className="mr-3 text-black dark:text-white bg-transparent">
              Domain First
            </span>
            <div
              className={`w-4 h-4 border border-gray-300 rounded-sm flex items-center justify-center cursor-pointer ${
                selectedButton === "domain" ? "bg-green-500" : "bg-transparent"
              }`}
              onClick={() => handleRadioClick("domain")}
            >
              {selectedButton === "domain" && (
                <svg
                  className="w-4 h-4 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
              )}
            </div>
          </label>
        </div>
      </div>

      <div className="container w-full md:w-3/4 mt-12">
        <div className="relative flex flex-col gap-1 mb-3">
          <div className="flex flex-col w-full">
            <h2 className="text-xl font-bold mb-2">Results</h2>
            <div className="bg-[#0ac74c] border border-none rounded-2xl">
              <div className="flex justify-between mx-4 my-1 font-bold text-white">
                <span className="">
                  {searchResult.available ? "Available" : "Not Available"}
                </span>
                <span className="">
                  {input.startsWith(".") || !input.includes(".")
                    ? "TLD"
                    : "Domain + TLD"}
                </span>
              </div>
              <div className="flex flex-col items-start md:flex-row md:items-center md:justify-between bg-white border md:p-4 p-1 rounded-2xl m-1">
                <div className="md:flex flex-col  md:mb-0">
                  <span className="text-[#0ac74c] font-bold mr-3">
                    {/* {!input.includes(".") ? "." : ""} */}
                    {input}
                  </span>
                  <span className="text-gray-600 ">
                    {searchResult.available ? "Available" : "Not Available"}
                  </span>
                </div>
                <span className="text-black  text-lg font-bold mb-2 md:mb-0">
                  {searchResult.available && <p>${searchResult.cost}</p>}
                </span>
                {searchResult.owner && (
                  <span className="flex justify-center items-center gap-2">
                    <span className="text-black  text-sm  mb-2 md:mb-0">
                      Owned by:
                    </span>
                    <span className="text-black  text-md  mb-2 md:mb-0">
                      <p>{searchResult.owner}</p>
                    </span>
                  </span>
                )}
                <div className="flex items-center">
                  {text2 === "Go to Cart" && (
                    <button
                      onClick={() => handleRemoveFromCart(input)} // Remove from cart when clicked
                      className="text-white px-2 py-1 rounded-full bg-red-500 hover:bg-red-600 mr-2"
                    >
                      ✕
                    </button>
                  )}

                  <button
                    value={input}
                    onClick={
                      text2 === "Add to Cart" ? handleClickCart : handleGoToCart
                    } // Navigate to cart if text is "Go to Cart"
                    className="text-white px-8 py-2 rounded-xl bg-[#0ac74c] hover:opacity-[0.9] w-full md:w-auto"
                  >
                    {text2}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <h2 className="text-xl font-bold mb-4">Suggested TLDs </h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            {stldData.map((item, index) => (
              <div key={index} className="p-6 bg-gray-100 rounded-xl shadow-sm">
                <h3 className="text-[#0ac74c] font-bold mb-2">
                {item.domain}
                </h3>
                <span className="text-[#0ac74c] text-xs uppercase font-semibold bg-purple-100 p-1 rounded">
                {item.available ? "Premium" : "Not Available"}
                </span>
                <span className="block text-black font-bold mt-2">{item.cost ? `$${item.cost}` : "-"}</span>
                <button
                  value={item.domain}
                  onClick={
                    !item.available
                      ? HandleAlert
                      : // : domainsData1.type === "Enterprise"
                        // ? handleOpenModal
                        handleClickCart
                  }
                  className="mt-4 text-white  px-4 py-2 rounded bg-[#0ac74c] hover:opacity-[0.9]"
                >
                  Add to Cart
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <label className="block">
          <Label>Email</Label>

          <Input
            onChange={(event: any) => setEmail1(event.target.value)}
            name="email"
            placeholder="example@gmail.com"
            type="text"
            className="mt-1"
            value={email}
          />
        </label>
        <label className="block">
          <Label>Occupation</Label>
          <select
            value={subject}
            onChange={(event: any) => setSubject(event.target.value)}
            name="subject"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ddark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
          >
            <option value="Student">Student</option>
            <option value="Company">Company</option>
            <option value="Employee">Employee</option>
            <option value="Engineer">Engineer</option>
            <option value="Other">Other</option>
          </select>
        </label>
        <label className="block">
          <Label>Message</Label>

          <Textarea
            name="content"
            value={content}
            onChange={(event: any) => setContent(event.target.value)}
            className="mt-1"
            rows={6}
          />
        </label>

        <div>
          <ButtonPrimary
          // onClick={recorded}
          >
            Submit
          </ButtonPrimary>
        </div>
      </Modal>

      {selectedButton === "tld" || selectedButton === null ? (
        <div className="my-20 flex flex-col items-center  justify-center container">
          <div className="flex flex-col items-center  justify-center">
            {/* <hr className="mx-auto w-5/6 "></hr> */}
            <h1 className="mx-auto w-5/6 text-xl font-bold mb-4">
              Suggested TLDs
            </h1>
            <span className="w-5/6 mx-auto text-base md:text-md text-neutral-500 dark:text-neutral-400">
              Choose the top-ranking TLDs suggested by UP algorithms. Expand to
              see more.
            </span>
            {tldData.map((item, index) => (
              <>
                <div
                  key={index}
                  className="flex  rounded-md lg:justify-end items-center lg:my-0 my-5 lg:space-x-32 flex-col lg:flex-row container"
                >
                  <h5 className="text-xl  text-justify text-gray-900 mt-2 dark:text-white">
                    {item.domain}
                  </h5>
                  <span className="w-32">
                    <div
                      className={
                        item.available
                          ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                          : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                      }
                    >
                      <p
                        className={
                          item.available
                            ? "text-sm leading-5 text-[#0ac74c]"
                            : "text-sm leading-5 text-red-700"
                        }
                      >
                        {item.available ? "Available" : "Not Available"}
                      </p>
                    </div>
                  </span>
                  <span className="text-xl mt-3  text-gray-900 dark:text-white">
                    {item.cost ? `$${item.cost}` : "-"}
                  </span>
                  <div className="flex justify-between mb-2">
                    <div className=" mt-4 ">
                      <button
                        value={item.domain}
                        className={
                          !item.available
                            ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded disabled:opacity-75 cursor-not-allowed"
                            : `${style1}`
                        }
                        onClick={
                          !item.available
                            ? HandleAlert
                            : // : domainsData1.type === "Enterprise"
                              // ? handleOpenModal
                              handleClickCart
                        }
                      >
                        {text1}
                      </button>
                    </div>
                  </div>
                </div>
                <hr className="mx-auto w-5/6 "></hr>
              </>
            ))}
          </div>
        </div>
      ) : (
        <div className="my-20 flex flex-col items-center  justify-center container">
          <div className="flex flex-col items-center  justify-center">
            {/* <hr className="mx-auto w-5/6 "></hr> */}
            <h1 className="mx-auto w-5/6 text-xl font-bold mb-4">
              Suggested Domains
            </h1>
            <span className="w-5/6 mx-auto text-base md:text-md text-neutral-500 dark:text-neutral-400">
              Choose the top-ranking TLDs suggested by UP algorithms. Expand to
              see more.
            </span>
            {domainData.map((item, index) => (
              <>
                <div
                  key={index}
                  className="flex  rounded-md lg:justify-end items-center lg:my-0 my-5 lg:space-x-32 flex-col lg:flex-row container"
                >
                  <h5 className="text-xl  text-justify text-gray-900 mt-2 dark:text-white">
                    {item.domain}
                  </h5>
                  <span className="w-32">
                    <div
                      className={
                        item.available
                          ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                          : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                      }
                    >
                      <p
                        className={
                          item.available
                            ? "text-sm leading-5 text-[#0ac74c]"
                            : "text-sm leading-5 text-red-700"
                        }
                      >
                        {item.available ? "Available" : "Not Available"}
                      </p>
                    </div>
                  </span>
                  <span className="text-xl mt-3  text-gray-900 dark:text-white">
                    {item.cost ? `$${item.cost}` : "-"}
                  </span>
                  <div className="flex justify-between mb-2">
                    <div className=" mt-4 ">
                      <button
                        value={item.domain}
                        className={
                          !item.available
                            ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded disabled:opacity-75 cursor-not-allowed"
                            : `${style1}`
                        }
                        onClick={
                          !item.available
                            ? HandleAlert
                            : // : domainsData1.type === "Enterprise"
                              // ? handleOpenModal
                              handleClickCart
                        }
                      >
                        {text1}
                      </button>
                    </div>
                  </div>
                </div>
                <hr className="mx-auto w-5/6 "></hr>
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthorPage;

import react, { FC, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Web3 from "web3";
export interface MydomainProps {
  domain?: string;
  price?: number;
  index?: number;
}
const Mydomain: FC<MydomainProps> = ({ domain = "", price, index }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [emailOrUsername, setEmailOrUsername] = useState("");

  let [categories] = useState(["domain", "index", "price"]);
  let navigate = useNavigate();
  function HandleClick() {
    navigate("/edit-domain-data", { state: { domain: domain } });
  }
  console.log("price", price);
  const isTLD = domain.startsWith(".");

  const handleTransferClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setWalletAddress("");
    setEmailOrUsername("");
  };

  const isValidAddress = (address: string): boolean => {
    return Web3.utils.isAddress(address); // Returns true if the address is valid (including checksum)
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValidAddress(walletAddress)) {
      toast.error("Invalid wallet address! Please enter a valid EVM-based address.");
      return;
    }
    // Handle transfer logic here
    console.log({ domain, walletAddress, emailOrUsername });
    closeModal();
  };

  return (
    <>
    <ToastContainer style={{
        width: '25%',
      }} position={"top-right"} />
      <tr className="dark:bg-neutral-800 bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
        <td className="px-6 py-4 dark:text-neutral-50 whitespace-nowrap text-sm font-medium text-gray-900">
          {index}
        </td>
        <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
          {domain}
        </td>
        <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
          {price}
        </td>
        {/* <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        <div className="w-32 flex items-center justify-center px-2 py-1 mt-2 bg-yellow-100 rounded-full">
          <p className="text-sm leading-5 text-yellow-700">
            {domainData.type}
          </p>
        </div>
      </td> */}
        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
          {isTLD ? (
            <ButtonPrimary onClick={handleTransferClick}>
              Transfer
            </ButtonPrimary>
          ) : (
            <ButtonPrimary onClick={HandleClick}>Edit</ButtonPrimary>
          )}
        </td>
      </tr>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="dark:bg-neutral-900 bg-white p-6 rounded-md shadow-lg w-full max-w-lg">
            <h2 className="text-lg font-bold mb-4 dark:text-white text-black">
              Transfer Domain: {domain}
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="walletAddress"
                  className="block text-sm font-medium mb-2 dark:text-white text-black"
                >
                  Wallet Address <span className="text-red-500">*</span>
                </label>
                <input
                  id="walletAddress"
                  type="text"
                  className="w-full px-3 py-2 border rounded dark:bg-neutral-800 bg-gray-100 dark:border-neutral-700 border-gray-300 dark:text-white text-black"
                  value={walletAddress}
                  onChange={(e) => setWalletAddress(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="emailOrUsername"
                  className="block text-sm font-medium mb-2 dark:text-white text-black"
                >
                  Email or Username (optional)
                </label>
                <input
                  id="emailOrUsername"
                  type="text"
                  className="w-full px-3 py-2 border rounded dark:bg-neutral-800 bg-gray-100 dark:border-neutral-700 border-gray-300 dark:text-white text-black"
                  value={emailOrUsername}
                  onChange={(e) => setEmailOrUsername(e.target.value)}
                />
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-300 rounded-3xl hover:opacity-[0.9] text-black dark:bg-neutral-700 dark:text-white"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-[#0ac74c] rounded-3xl hover:opacity-[0.9] text-white "
                >
                  Transfer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
export default Mydomain;

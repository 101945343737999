import React, { useState, useEffect, FormEvent } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Congratulations from '../images/congrats.gif';
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Heading from "components/Heading/Heading";
import axios from "axios";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";
import Web3 from "web3";
import { gql, useMutation } from "@apollo/client";
import { UserGlobalContext } from "user";

const AIRDROP_DOMAIN = gql`
  mutation AirdropDomain($address: String!, $domains: [String!]!, $cost: Float!) {
    airdropDomain(address: $address, domains: $domains, cost: $cost) {
      success
      message
      profile {
        email
        domains {
          name
          walletAddress
          cost
        }
      }
    }
  }
`;

const PageAirdrop = () => {
  const { state } = useLocation();
  const [address, setAddress] = useState("")
  const [alertText, setAlertText] = useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [clicked, setClicked] = useState(false);
  const [domain, setDomain] = useState(state.domain)
  const { userProfile } = UserGlobalContext();
  let navigate = useNavigate();
  const location = useLocation();
  const { domains, plan, cost } = location.state || {};
  console.log("domainss: " + domains);
  console.log("costsss: " + plan);

  console.log("dom cost", cost);

  const [airdropDomain, { data, loading, error }] = useMutation(AIRDROP_DOMAIN);

  // console.log("dom", state.domain);
  // const navigate = useNavigate()
  // let dom;
  // setDomain(dom)
  // const dom = state.domain;
  console.log("dom",domain)
  
// setDomain(dom)

  const web3 = new Web3();

  // Function to validate Ethereum address format using web3.js
  const isValidAddress = (address: string): boolean => {
    return web3.utils.isAddress(address); // Returns true if the address is valid (including checksum)
  };
  // const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   if (!isValidAddress(address)) {
  //     toast.error("Invalid wallet address! Please enter a valid EVM-based address.");
  //     return;
  //   }
  //   // If validation passes, you can submit the form
  //   event.currentTarget.submit();
  // };
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!isValidAddress(address)) {
      toast.error("Invalid wallet address! Please enter a valid EVM-based address.");
      return;
    }
    navigate("/congrats");


    // try {
    //   // Execute the GraphQL mutation
    //   const response = await airdropDomain({
    //     variables: {
    //       address,
    //       domains,
    //       cost,
    //     },
    //   });

    //   // Handle successful response
    //   if (response.data.airdropDomain.success) {
    //     toast.success("Domain claimed successfully!");
    //     navigate("/congrats");
    //   } else {
    //     toast.error(response.data.airdropDomain.message);
    //   }
    // } catch (err) {
    //   console.error("Airdrop Error:", err);
    //   toast.error("Error processing domain airdrop.");
    // }
  };
  return (
    <>
      <ToastContainer style={{
        width: '25%',
      }} position={"top-right"} />

      <div className={`nc-AuthorPage  w-full `} data-nc-id="AuthorPage">
        <Heading
          className="mt-20 mb-10 text-neutral-900 dark:text-neutral-50"
          fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
          isCenter
          desc=""
        >
          Claim Your Free Domain
        </Heading>


        <form
          onSubmit={handleSubmit}
          className="mt-15 h-[40vh] gap-7 flex flex-col content-center items-center justify-center rounded overflow-hidden ">
          <div className="container xl:w-2/3 sm:w-2/3 md:w-2/3 w-[90%]">
            <div className="relative">
              <Input
                value={address}
                name="address"
                id="address"
                // onChange={HandleChange}
                // onKeyDown={HandleEnter}
                onChange={(event: any) => setAddress(event.target.value)}
                type="search"
                placeholder="Enter wallet address"
                className="pr-16"
                sizeClass="h-[42px] pl-4 py-6"
                required
              />
              <span className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500"></span>

              <Input className="" type="hidden" name="domain" value={domains} id="domain"/>
              <Input className="" type="hidden" name="cost" value={state.cost} id="cost"/>
            </div>
          </div>
          <div className="mb-8">
            <ButtonPrimary 
            // onClick={recorded}
            type="submit"
            // onClick={() => {
            //   setClicked(!clicked);
            // }}
            >Claim</ButtonPrimary>
          </div>
        </form>
      </div>
    </>
  );
};
export default PageAirdrop;
// https://img.icons8.com/bubbles/200/000000/trophy.png
import React, { useState, useEffect } from "react";
import Axios from "axios";
import { UserGlobalContext } from "user";
import { gql, useLazyQuery } from "@apollo/client";


const SEARCH_DOMAIN = gql`
  query SearchDomain($name: String!) {
    searchDomain(name: $name) {
      available
      cost
      message
    }
  }
`;

export default function CartItemsList(props: any) {
  const { changeCartItems, changeArray, array } = UserGlobalContext();
  const domain = props.name;
  const extension = domain.split(".")[1];
  const [domainData, changeDomainData] = useState({
    cost: 20,
    type: "Getting Data...",
  });
  const [cost, changeCost] = useState(domainData.cost);
  const [searchResult, setSearchResult] = useState({ available: false, cost: 0, message: '' });
  
  // useLazyQuery for conditional query execution
  const [searchDomain, { loading, error, data }] = useLazyQuery(SEARCH_DOMAIN, {
    onCompleted: (data) => {
      console.log("Search Domain Response:", data); // Log response to console
      setSearchResult(data.searchDomain);
    },
  });

  const domainType = domain.startsWith(".") ? "TLD" : "Domain + TLD";

  useEffect(() => {
    // Axios.get("http://localhost:5000/search/" + domain)
    //   .then((response) => {
    //     changeDomainData(response.data);
    //   })
    //   .catch((err) => console.log(err));
    searchDomain({ variables: { name: domain } });
  }, [domain]);


 {
    return (
      <div className="mt-4 md:mt-6 flex  flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full ">
        {/* <div className="pb-4 md:pb-8 w-full md:w-40">
                    <img className="w-full hidden md:block" src="https://i.ibb.co/84qQR4p/Rectangle-10.png" alt="dress" />
                    <img className="w-full md:hidden" src="https://i.ibb.co/L039qbN/Rectangle-10.png" alt="dress" />
                </div> */}
        <div className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full  pb-8 space-y-4 md:space-y-0">
          <div className="w-full flex flex-col justify-start items-start space-y-8">
            <h3 className="dark:text-neutral-50 text-xl xl:text-2xl font-semibold leading-6 text-gray-800">
              {domain}
            </h3>
            <div className="flex justify-start items-start flex-col space-y-2">
              <p className="dark:text-neutral-50 text-sm leading-none text-gray-800">
                <span className="text-gray-400">Extension: </span> {domainType}
              </p>
              {/* <p className="text-sm leading-none text-gray-800">
                                <span className="text-gray-300">Size: </span> Small
                            </p>
                            <p className="text-sm leading-none text-gray-800">
                                <span className="text-gray-300">Color: </span> Light Blue
                            </p> */}
            </div>
          </div>
          <div className="flex justify-between space-x-8 items-start w-full">
            <p className="dark:text-neutral-50 text-base xl:text-lg leading-6 text-gray-800">
              {/* ${searchResult.cost}{" "} */}
              $20
              {/* <span className="text-red-300 line-through">
                {" "}
                ${domainData.cost}
              </span> */}
            </p>
            <p className="dark:text-neutral-50 text-base xl:text-lg leading-6 text-gray-800"></p>
            <p className="dark:text-neutral-50 text-base xl:text-lg font-semibold leading-6 text-gray-800">
              {searchResult.available ? "Available" : "Not Available"}
            </p>
            <button
              value={props.id}
              onClick={props.change}
              className="bg-red-500 p-1 rounded text-xs text-white"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    );
  }
}

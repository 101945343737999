import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import Mydomain from "containers/AuthorPage/mydomain";
import Logo from "shared/Logo/Logo";

const TableLayout = ({ data, title }: { data: any[]; title: string }) => {
  if (!data.length) return null;

  return (
    <div className="mb-8 w-full md:w-3/4 mx-auto">
      <h2 className="text-lg font-bold mb-4">{title}</h2>
      <table className="min-w-full">
        <thead>
          <tr>
            <th className="px-6 py-3 text-left">Index</th>
            <th className="px-6 py-3 text-left">Domain</th>
            <th className="px-6 py-3 text-left">Price</th>
            <th className="px-6 py-3 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((domain: any, index: number) => (
            <Mydomain
              key={index}
              index={index + 1}
              domain={domain.name}
              price={domain.cost}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

function Domains() {
  const [domains, setDomains] = useState([
    { name: "example.upu", cost: "20", walletAddress: "0x123...", txhash: "0xabc..." },
    { name: "mydomain.verse", cost: "20", walletAddress: "0x456...", txhash: "0xdef..." },
    { name: ".asap", cost: "20 ", walletAddress: "0x789...", txhash: "0xghi..." },
    { name: ".rush", cost: "20", walletAddress: "0x101...", txhash: "0xjkl..." },
  ]);

  // Separate normal domains and TLDs
  const normalDomains = domains.filter((domain: { name: string }) => !domain.name.startsWith("."));
  const tlds = domains.filter((domain: { name: string }) => domain.name.startsWith("."));

  return (
    <div>
      {domains.length ? (
        <>
          <TableLayout data={normalDomains} title="Domains" />
          <TableLayout data={tlds} title="TLDs" />
        </>
      ) : (
        <div className="text-center">
          <h1 className="text-3xl my-4 font-bold text-center">Not Available</h1>
          <h2 className="text-3xl my-4 font-bold text-center">
            Buy your web3 domain, Now!
          </h2>
          <ButtonPrimary>
            Buy Now<Link to="/" className="absolute inset-0"></Link>
          </ButtonPrimary>
        </div>
      )}
    </div>
  );
}

export default Domains;
